import React from "react";
import img13 from "../../images/13.png";

function About() {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8">
          Get to Know about Connecticx
        </h2>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="md:w-1/2">
            <img
              src={img13}
              alt="People socializing at a bar"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/2">
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Our Mission:</h3>
              <p className="text-gray-600">
                At Connecticx, we aim to bring people together through a shared
                love for experiences. Our mission is to provide a platform where
                anyone can discover, attend, or create events that inspire fun,
                learning, adventure, and professional growth. We believe in the
                power of events to connect individuals, foster creativity, and
                build strong communities.
              </p>
            </div>
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">Our Vision:</h3>
              <p className="text-gray-600">
                To be the go-to platform for event discovery and creation,
                transforming the way people engage with their cities and with
                each other.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-2">Our Values:</h3>
              <ul className="list-disc list-inside text-gray-600">
                <li>
                  Connection: We strive to connect people through events that
                  matter.
                </li>
                <li>
                  Diversity: We embrace a wide range of events, from business
                  conferences to adventure sports.
                </li>
                <li>
                  Innovation: We continually enhance our platform to offer the
                  best user experience.
                </li>
                <li>
                  Learning: We promote events that encourage growth, education,
                  and personal development.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;

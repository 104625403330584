import React from "react";
import {
  SearchIcon,
  CalendarIcon,
  // TicketIcon,
  ChartBarIcon,
  QrcodeIcon,
  UserGroupIcon,
  SupportIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "Event Discovery",
    description:
      "Explore a wide range of events with personalized recommendations.",
    icon: SearchIcon,
  },
  {
    name: "Event Creation",
    description:
      "Easily create and customize events with flexible ticketing options.",
    icon: CalendarIcon,
  },
  {
    name: "Promotion",
    description:
      "Reach your audience with targeted marketing and promotional tools.",
    icon: UserGroupIcon,
  },
  {
    name: "Analytics",
    description:
      "Track ticket sales and gain insights with real-time analytics.",
    icon: ChartBarIcon,
  },
  {
    name: "Secure Ticketing",
    description: "Instant e-tickets and smooth check-in with QR code scanning.",
    icon: QrcodeIcon,
  },
  {
    name: "Networking",
    description:
      "Connect with like-minded people and engage with interactive features.",
    icon: UserGroupIcon,
  },
  {
    name: "Event Management",
    description: "Manage everything in one place with our organizer dashboard.",
    icon: CalendarIcon,
  },
  {
    name: "Support",
    description: "Rapid support available to users when needed.",
    icon: SupportIcon,
  },
];

function FeatureCard({ feature }) {
  const Icon = feature.icon;
  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4">
      <Icon className="h-14 w-16 text-purple-600" />
      <div>
        <h3 className="text-lg font-semibold mb-2">{feature.name}</h3>
        <p className="text-gray-600">{feature.description}</p>
      </div>
    </div>
  );
}

function Features() {
  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8 text-center">
          Features of Connecticx
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <FeatureCard key={index} feature={feature} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Features;

// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBIu4pZw_GFUOZYknkd_jl1r3aSAV9n41g",
  authDomain: "connecticx-eca48.firebaseapp.com",
  projectId: "connecticx-eca48",
  storageBucket: "connecticx-eca48.appspot.com",
  messagingSenderId: "56674718618",
  appId: "1:56674718618:web:23565da9ecccacfb556023",
  measurementId: "G-LZQTWBPMSN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { auth, analytics, db };
export default app;

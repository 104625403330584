import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import logo from "../../images/logo.png";
import { auth } from "../../firebase";
import { signOut } from "firebase/auth";

function Header() {
  const navigate = useNavigate();
  // State to manage dropdown and mobile menu visibility
  const [isCustomerDropdownOpen, setIsCustomerDropdownOpen] = useState(false);
  const [isOrganizerDropdownOpen, setIsOrganizerDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [user, setUser] = useState(null);

  // Check authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  // Logout handler
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // Toggle dropdown function for Customer and Organizer
  const toggleCustomerDropdown = () => {
    setIsCustomerDropdownOpen(!isCustomerDropdownOpen);
    setIsOrganizerDropdownOpen(false); // Close organizer dropdown if open
  };

  const toggleOrganizerDropdown = () => {
    setIsOrganizerDropdownOpen(!isOrganizerDropdownOpen);
    setIsCustomerDropdownOpen(false); // Close customer dropdown if open
  };

  // Toggle mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close dropdowns and mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest(".customer-dropdown") &&
        !event.target.closest(".organizer-dropdown") &&
        !event.target.closest(".mobile-menu")
      ) {
        setIsCustomerDropdownOpen(false);
        setIsOrganizerDropdownOpen(false);
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Close dropdown and mobile menu when an option is clicked
  const closeDropdownsAndMenu = () => {
    setIsCustomerDropdownOpen(false);
    setIsOrganizerDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <header className="fixed top-0 left-0 w-full bg-white shadow-md z-50">
        <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
          {/* Logo Section */}
          <Link to="/" className="text-xl font-bold flex items-center">
            <img
              src={logo}
              alt="Connecticx Logo"
              className="mr-2"
              style={{
                width: "150px",
                height: "auto",
                border: "1px solid white",
              }}
            />
          </Link>

          {/* Navigation Links (Desktop) */}
          <div className="hidden md:flex items-center space-x-8 text-lg">
            <Link to="/events" className="hover:text-purple-600 py-2 px-4">
              Events
            </Link>
            <Link
              to="/how-it-works"
              className="hover:text-purple-600 py-2 px-4"
            >
              How It Works
            </Link>
            <Link
              to="/customer-login"
              className="hover:text-purple-600 py-2 px-4"
            >
              Find Your Ticket
            </Link>
          </div>

          {/* Action Buttons with Clickable Dropdowns (Desktop) */}
          <div className="hidden md:flex items-center space-x-4">
            {/* If no user is logged in, show login/signup buttons */}
            {!user ? (
              <>
                {/* Customer Button with Dropdown */}
                <div className="relative customer-dropdown">
                  <button
                    className="bg-purple-500 text-white px-4 py-2 rounded-full hover:bg-purple-700"
                    onClick={toggleCustomerDropdown}
                  >
                    Customer
                  </button>
                  {isCustomerDropdownOpen && (
                    <ul className="absolute left-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg">
                      <li className="px-4 py-2 hover:bg-purple-100">
                        <Link
                          to="/customer-login"
                          className="text-gray-700"
                          onClick={closeDropdownsAndMenu}
                        >
                          Login
                        </Link>
                      </li>
                      <li className="px-4 py-2 hover:bg-purple-100">
                        <Link
                          to="/customer-signup"
                          className="text-gray-700"
                          onClick={closeDropdownsAndMenu}
                        >
                          Signup
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>

                {/* Organizer Button with Dropdown */}
                <div className="relative organizer-dropdown">
                  <button
                    className="border border-purple-500 text-purple-500 px-4 py-2 rounded-full hover:bg-purple-100"
                    onClick={toggleOrganizerDropdown}
                  >
                    Organizer
                  </button>
                  {isOrganizerDropdownOpen && (
                    <ul className="absolute left-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg">
                      <li className="px-4 py-2 hover:bg-purple-100">
                        <Link
                          to="/organizer-login"
                          className="text-gray-700"
                          onClick={closeDropdownsAndMenu}
                        >
                          Login
                        </Link>
                      </li>
                      <li className="px-4 py-2 hover:bg-purple-100">
                        <Link
                          to="/organizer-signup"
                          className="text-gray-700"
                          onClick={closeDropdownsAndMenu}
                        >
                          Signup
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </>
            ) : (
              // If user is logged in, show logout button
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600"
              >
                Logout
              </button>
            )}
          </div>

          {/* Mobile Menu Toggle */}
          <div className="md:hidden flex items-center space-x-4">
            {!user ? (
              <>
                <div className="relative customer-dropdown">
                  <button
                    className="bg-purple-500 text-white px-4 py-2 rounded-full hover:bg-purple-700"
                    onClick={toggleCustomerDropdown}
                  >
                    Customer
                  </button>
                  {isCustomerDropdownOpen && (
                    <ul className="absolute left-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg">
                      <li className="px-4 py-2 hover:bg-purple-100">
                        <Link
                          to="/customer-login"
                          className="text-gray-700"
                          onClick={closeDropdownsAndMenu}
                        >
                          Login
                        </Link>
                      </li>
                      <li className="px-4 py-2 hover:bg-purple-100">
                        <Link
                          to="/customer-signup"
                          className="text-gray-700"
                          onClick={closeDropdownsAndMenu}
                        >
                          Signup
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
                <div className="relative organizer-dropdown">
                  <button
                    className="border border-purple-500 text-purple-500 px-4 py-2 rounded-full hover:bg-purple-100"
                    onClick={toggleOrganizerDropdown}
                  >
                    Organizer
                  </button>
                  {isOrganizerDropdownOpen && (
                    <ul className="absolute left-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg">
                      <li className="px-4 py-2 hover:bg-purple-100">
                        <Link
                          to="/organizer-login"
                          className="text-gray-700"
                          onClick={closeDropdownsAndMenu}
                        >
                          Login
                        </Link>
                      </li>
                      <li className="px-4 py-2 hover:bg-purple-100">
                        <Link
                          to="/organizer-signup"
                          className="text-gray-700"
                          onClick={closeDropdownsAndMenu}
                        >
                          Signup
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </>
            ) : (
              <button
                onClick={handleLogout}
                className="bg-red-500 text-white px-4 py-2 rounded-full hover:bg-red-600"
              >
                Logout
              </button>
            )}
            <button
              className="text-gray-600 hover:text-purple-600 focus:outline-none"
              onClick={toggleMobileMenu}
            >
              {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </nav>
      </header>

      {/* Mobile Menu (Visible on Small Screens) */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed top-16 left-0 w-full bg-white border-t border-gray-200 shadow-md z-40 mobile-menu">
          <div className="container mx-auto px-6 py-3 space-y-2">
            <Link
              to="/events"
              className="block text-lg hover:text-purple-600 py-5 px-4"
              onClick={closeDropdownsAndMenu}
            >
              Events
            </Link>
            <Link
              to="/how-it-works"
              className="block text-lg hover:text-purple-600 py-2 px-4"
              onClick={closeDropdownsAndMenu}
            >
              How It Works
            </Link>
            <Link
              to="/customer-login"
              className="block text-lg hover:text-purple-600 py-2 px-4"
              onClick={closeDropdownsAndMenu}
            >
              Find Your Ticket
            </Link>
          </div>
        </div>
      )}

      {/* Main Content Padding */}
      <main className="pt-20">{/* Your main content goes here */}</main>
    </>
  );
}

export default Header;

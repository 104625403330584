import React from "react";
import { useNavigate } from "react-router-dom";
import img19 from "../../images/19.png";
import img20 from "../../images/20.png";
import img40 from "../../images/yoga.jpg";

const events = [
  {
    id: 1,
    title: "Yoga and Mindfulness by Utsaahi",
    date: "December 15, 2024",
    time: "7:00 AM - 8:00 AM",
    location: "Japanese Garden, Nagpur",
    description:
      "Discover inner peace and rejuvenation through a holistic wellness experience. Engage in soothing Asanas, revitalizing Pranayama, deep Self-Connection, and empowering Journaling and Affirmation Practice.",
    image: img40,
    price: "Free",
    originalPrice: 60,
    organizer: "Utsaahi",
  },
  {
    id: 2,
    title: "Digital Marketing Bootcamp",
    date: "September 5, 2024",
    time: "9:00 AM - 4:00 PM",
    location: "TechHub, Austin, TX",
    description:
      "A full-day workshop designed to equip participants with the latest digital marketing strategies. Ideal for small business owners, entrepreneurs, and marketing professionals.",
    image: img20,
    price: 150,
    organizer: "tech_guru",
  },
  {
    id: 3,
    title: "Entrepreneurs' Roundtable",
    date: "August 20, 2024",
    time: "6:00 PM - 8:30 PM",
    location: "The Loft, San Francisco, CA",
    description:
      "Connect with fellow entrepreneurs and business leaders at this exclusive networking event. Includes a panel discussion, Q&A session, and cocktail reception.",
    image: img19,
    price: 100,
    organizer: "business_expert",
  },
];

function EventCard({ event }) {
  const navigate = useNavigate();

  const handleClick = () => {
    const eventSlug = event.title.replaceAll(" ", "-").toLowerCase(); // Convert title to a URL-friendly slug
    navigate(`/event/${eventSlug}`); // Navigate using the slug
  };

  const renderPrice = () => {
    if (event.id === 1) {
      return "Free"; // Special case for event ID 1
    }
    return event.price === 0 ? "Free" : `₹${event.price}`;
  };

  return (
    <div
      className="bg-purple-700 rounded-lg shadow-lg hover:shadow-xl transition duration-300 cursor-pointer overflow-hidden text-white"
      onClick={handleClick}
    >
      <img
        src={event.image}
        alt={event.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <h3 className="font-bold text-lg mb-2">{event.title}</h3>
        <p className="text-sm text-purple-300 mb-4">{event.description}</p>
        <div className="text-purple-100 font-bold">{renderPrice()}</div>
      </div>
    </div>
  );
}

function EventList() {
  return (
    <div className="h-auto py-10 mt-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {events.map((event) => (
            <EventCard key={event.id} event={event} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default EventList;

import React from "react";

const steps = [
  {
    number: 1,
    title: "Explore Events",
    description: "Browse through our wide range of events.",
  },
  {
    number: 2,
    title: "Select & Book",
    description: "Choose your favorite event and secure your spot.",
  },
  {
    number: 3,
    title: "Get Your Ticket",
    description: "Receive your e-ticket instantly via email.",
  },
  {
    number: 4,
    title: "Attend & Enjoy",
    description: "Show up at the venue and have a great time!",
  },
];

function Step({ step }) {
  return (
    <div className="flex flex-col items-center">
      <div className="bg-purple-600 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold mb-4">
        {step.number}
      </div>
      <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
      <p className="text-gray-600 text-center">{step.description}</p>
    </div>
  );
}

function About() {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8 text-center">
          How Does It Work
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <Step key={index} step={step} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;

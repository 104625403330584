import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import CustomerLogin from "./components/Customers/CustomerLogin";
import CustomerSignup from "./components/Customers/CustomerSignup";
import OrganizerLogin from "./components/Organizers/OrganizerLogin";
import OrganizerSignup from "./components/Organizers/OrganizerSignup";
import EventList from "./components/EventList/EventList";
import EventDetails from "./components/EventList/EventDetails";
import Dashboard from "./components/Customers/Dashboard";
import OrganizerDashboard from "./components/Organizers/OrganizerDashboard";
import CheckoutPage from "./components/EventList/Checkout";
import ConfirmationPage from "./components/EventList/Confirmation";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import About from "./components/Footer/About";
import Terms from "./components/Footer/Terms";
import Contact from "./components/Footer/Contact";
// import OnlineEventForm from "./components/Organizers/OrganizerDashboard/OnlineEventForm";
// import VenueEventForm from "./components/Organizers/OrganizerDashboard/VenueEventForm";
// import OrganizerLogin from "./components/Organizer/OrganizerLogin";
// import OrganizerSignup from "./components/Organizer/OrganizerSignup";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/customer-login" element={<CustomerLogin />} />
            <Route path="/customer-signup" element={<CustomerSignup />} />
            <Route path="/organizer-login" element={<OrganizerLogin />} />
            <Route path="/organizer-signup" element={<OrganizerSignup />} />
            <Route path="/event/:slug" element={<EventDetails />} />
            <Route path="/events" element={<EventList />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route path="/" element={<EventList />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />

            {/* <Route path="/online-event" element={<OnlineEventForm />} />
            <Route path="/venue-event" element={<VenueEventForm />} /> */}
            <Route
              path="/confirmation/:orderId"
              element={<ConfirmationPage />}
            />
            <Route
              path="/organizer-dashboard"
              element={<OrganizerDashboard />}
            />

            {/* <Route path="/customer-signup" element={<CustomerSignup />} />
            <Route path="/organizer-login" element={<OrganizerLogin />} />
            <Route path="/organizer-signup" element={<OrganizerSignup />} /> */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

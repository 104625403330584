import React from "react";
import {
  FaSearch,
  FaHandPointer,
  FaShoppingCart,
  FaSmile,
} from "react-icons/fa"; // Import icons

const steps = [
  {
    number: 1,
    title: "Explore Events",
    description:
      "Explore curated city events by category, including fun, learning, sports, adventure, business, and more.",
    icon: <FaSearch size={40} color="#8B5CF6" />,
  },
  {
    number: 2,
    title: "Select & Book",
    description:
      "Choose: Click on the event that interests you, view details, and easily purchase tickets through our secure platform.",
    icon: <FaHandPointer size={40} color="#8B5CF6" />,
  },
  {
    number: 3,
    title: "Get Your Ticket",
    description:
      "Access: Receive your e-ticket instantly easy access on the go.",
    icon: <FaShoppingCart size={40} color="#8B5CF6" />,
  },
  {
    number: 4,
    title: "Attend & Enjoy",
    description:
      "Experience: Show up at the event and scan your ticket and enjoy the moment, knowing everything was taken care of seamlessly.",
    icon: <FaSmile size={40} color="#8B5CF6" />,
  },
];

function Step({ step }) {
  return (
    <div className="flex flex-col items-center text-center relative">
      {/* Icon with number badge */}
      <div className="relative mb-4">
        {/* Icon Background Circle */}
        <div className="bg-blue-100 w-24 h-24 rounded-full flex items-center justify-center">
          {step.icon}
        </div>
        {/* Number Badge */}
        <div className="absolute top-0 right-0 bg-purple-600 text-white rounded-full w-6 h-6 flex items-center justify-center font-bold text-sm">
          {step.number}
        </div>
      </div>

      {/* Step Title and Description */}
      <h3 className="text-lg font-semibold mb-2">{step.title}</h3>
      <p className="text-gray-600">{step.description}</p>
    </div>
  );
}

function HowItWorks() {
  return (
    <section className="py-12 bg-gray-50">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold text-center mb-2">
          How Does It Work
        </h2>
        <p className="text-gray-600 text-center mb-8">
          For Event Attendees or Vibe Chasers
        </p>

        {/* Steps in Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {steps.map((step, index) => (
            <Step key={index} step={step} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;

import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import logo from "../../images/logo.png";

const TicketComponent = ({ orderDetails }) => {
  if (!orderDetails) {
    return <div>Loading ticket details...</div>;
  }

  const qrCodeData = JSON.stringify({
    ticketId: orderDetails.ticketId,
    eventName: orderDetails.eventTitle,
    attendeeName: `${orderDetails.firstName} ${orderDetails.lastName}`,
  });

  return (
    <div className="w-full max-w-3xl mx-auto bg-white p-12 rounded-xl shadow-lg border-2 border-purple-500">
      {/* Logo Section */}
      <div className="text-center mb-8">
        <img src={logo} alt="Event Logo" className="h-24 mx-auto mb-6" />
        <h1 className="text-3xl font-bold text-purple-600">Event Ticket</h1>
      </div>

      {/* Ticket Details Section */}
      <div className="space-y-6 mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[
            { label: "Event", value: orderDetails.eventTitle },
            {
              label: "Name",
              value: `${orderDetails.firstName} ${orderDetails.lastName}`,
            },
            {
              label: "Date & Time",
              value: `${orderDetails.eventDate}\n${orderDetails.eventTime}`,
            },
            { label: "Location", value: orderDetails.eventLocation },
            { label: "Email", value: orderDetails.email },
          ].map(({ label, value }) => (
            <div
              key={label}
              className="p-5 bg-purple-50 rounded-lg border border-purple-100"
            >
              <p className="font-semibold text-purple-700 mb-2">{label}</p>
              <p className="text-gray-800 whitespace-pre-line">{value}</p>
            </div>
          ))}
        </div>
      </div>

      {/* QR Code Section */}
      <div className="text-center mt-8">
        <p className="font-semibold text-purple-700 mb-4 text-lg">
          Scan QR for Entry
        </p>
        <div className="flex justify-center">
          <div className="inline-block p-5 bg-white rounded-lg shadow-md border border-purple-200">
            <QRCodeCanvas
              value={qrCodeData}
              size={200} // Increased size
              level="H"
              includeMargin={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketComponent;

import React from "react";
import Hero from "../components/Hero/Hero";
import EventList from "../components/EventList/EventList";
import Categories from "../components/Categories/Categories";
import About from "../components/About/About";
import Features from "../components/Features/Features";
import HowItWorks from "../components/HowItWorks/HowItWorks";
import Testimonials from "../components/Testimonials/Testimonials";

function Home() {
  return (
    <div>
      <Hero />
      <EventList />
      <Categories />
      <About />
      <Features />
      <HowItWorks />
      <Testimonials />
    </div>
  );
}

export default Home;

import React from "react";

function Contact() {
  return (
    <div className="bg-white font-sans">
      {/* Header Section */}
      <header className=" text-black text-center py-16">
        <h1 className="text-4xl font-bold">Contact</h1>
        <p className="mt-2">Home / Contact</p>
      </header>

      {/* Contact Information and Form */}
      <div className="container mx-auto py-12 px-4 flex flex-col md:flex-row gap-8">
        {/* Contact Information */}
        <div className="bg-gray-100 rounded-lg p-8 flex flex-col gap-6 w-full md:w-1/2">
          <div className="flex items-start gap-4">
            <span className="text-purple-500 text-2xl">&#128205;</span>
            <div>
              <h3 className="text-xl font-semibold">Our Address</h3>
              <p>India, Maharashtra</p>
              <p>Nagpur, Shivaji Nagar</p>
              <p>440010</p>
            </div>
          </div>

          <div className="flex items-start gap-4">
            <span className="text-purple-500 text-2xl">&#9993;</span>
            <div>
              <h3 className="text-xl font-semibold">Our Email</h3>
              <p>example@example.com</p>
            </div>
          </div>

          <div className="flex items-start gap-4">
            <span className="text-purple-500 text-2xl">&#128222;</span>
            <div>
              <h3 className="text-xl font-semibold">Our Phone</h3>
              <p>(225)571-6212</p>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <div className="w-full md:w-1/2 bg-white p-8 shadow-md rounded-lg">
          <h3 className="text-2xl font-semibold mb-6">Send A Message</h3>
          <form className="space-y-4">
            <div className="flex gap-4">
              <input
                type="text"
                placeholder="Enter Your Full Name"
                className="border p-2 w-full rounded-md"
              />
              <input
                type="email"
                placeholder="Enter Your Email"
                className="border p-2 w-full rounded-md"
              />
            </div>
            <input
              type="text"
              placeholder="Enter Email Subject"
              className="border p-2 w-full rounded-md"
            />
            <textarea
              placeholder="Write Your Message"
              className="border p-2 w-full rounded-md h-32"
            ></textarea>
            <button
              type="submit"
              className="bg-purple-500 text-white px-6 py-2 rounded-md"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;

import React from "react";
import { useNavigate } from "react-router-dom";

// Correcting the image path
// import img1 from "../../images/1.png";
// import img2 from "../../images/2.jpg";
import img3 from "../../images/3.png";
import img4 from "../../images/4.png";
import img5 from "../../images/5.png";
// import img6 from "../../images/6.png";
// import img7 from "../../images/7.png";
// import img8 from "../../images/8.png";
import img19 from "../../images/19.png";
import img15 from "../../images/15.png";
import img18 from "../../images/18.png";
import img20 from "../../images/20.png";
import img16 from "../../images/16.png";
import img17 from "../../images/17.png";

const categories = [
  {
    name: "Food & Drink",
    image: img3,
    slug: "food-and-drink",
  },
  {
    name: "Workshop & Classes",
    image: img5,
    slug: "workshops-and-classes",
  },
  {
    name: "Networking & Business",
    image: img15,
    slug: "networking-and-business",
  },
  {
    name: "Health & Wellness",
    image: img18,
    slug: "health-and-wellness",
  },
  {
    name: "Conference & Seminar",
    image: img20,
    slug: "conferences-and-seminars",
  },
  {
    name: "Sports & Adventure",
    image: img16,
    slug: "sports-and-adventure",
  },
  {
    name: "Music & Nightlife",
    image: img4,
    slug: "music-and-nightlife",
  },
  {
    name: "Arts & Lifestyle",
    image: img17,
    slug: "arts-and-lifestyle",
  },
  {
    name: "Exhibitions & Trade Shows",
    image: img19,
    slug: "exhibitions-and-trade-shows",
  },
];

function ExploreCategories() {
  const navigate = useNavigate();

  const handleCategoryClick = (slug) => {
    navigate(`/events`);
  };

  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8 text-center">
          Explore By Category
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {categories.map((category, index) => (
            <div
              key={index}
              className="relative group overflow-hidden rounded-lg cursor-pointer"
              onClick={() => handleCategoryClick(category.slug)}
            >
              <img
                src={category.image}
                alt={category.name}
                className="w-full h-48 object-cover transition-transform duration-300 group-hover:scale-110"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center transition-opacity duration-300 group-hover:bg-opacity-70">
                <span className="text-white text-center font-semibold px-2">
                  {category.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ExploreCategories;

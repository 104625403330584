import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../../firebase";

function CustomerLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // Check for messages or intended event details
  useEffect(() => {
    if (location.state) {
      const { message } = location.state;

      if (message) {
        setMessage(message);
      }
    }
  }, [location.state]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (!userCredential.user.emailVerified) {
        setError("Please verify your email before logging in.");
        return;
      }

      // Check if there's an intended event from session storage
      const intendedEventId = sessionStorage.getItem("intendedEventId");
      const intendedEventSlug = sessionStorage.getItem("intendedEventSlug");

      // Clear session storage
      sessionStorage.removeItem("intendedEventId");
      sessionStorage.removeItem("intendedEventSlug");

      if (intendedEventSlug) {
        // Redirect to the event checkout
        navigate("/checkout", {
          state: {
            eventId: intendedEventId,
            fromEventPage: true,
          },
        });
      } else {
        // Default redirect to dashboard
        navigate("/dashboard");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email address first");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Check your inbox.");
      setError("");
    } catch (error) {
      setError(error.message);
      setMessage("");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center">
      <div className="max-w-md w-full mx-auto bg-white p-8 shadow-md">
        <div className="text-center mb-4">
          <h2 className="text-3xl font-bold">Customer Login</h2>
          <p>Home / Customer Login</p>
        </div>

        {message && <p className="text-green-500 mb-4">{message}</p>}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block mb-2">Email</label>
            <input
              type="email"
              className="w-full p-2 border"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Password</label>
            <input
              type="password"
              className="w-full p-2 border"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-green-600 text-white py-2 px-4 rounded mb-2"
          >
            Login
          </button>
        </form>

        <div className="text-center">
          <button
            onClick={handleForgotPassword}
            className="text-blue-600 mb-4 hover:underline"
          >
            Forgot Password?
          </button>
        </div>

        <div className="text-center">
          <Link to="/customer-signup" className="text-blue-600">
            Don't have an account? Signup Now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CustomerLogin;

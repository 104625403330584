import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import qrcode from "qrcode";

const Dashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchBookings(currentUser.email);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  // Logout handler
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const fetchBookings = async (userEmail) => {
    try {
      const bookingsRef = collection(db, "orders");
      const q = query(bookingsRef, where("email", "==", userEmail));

      const querySnapshot = await getDocs(q);
      const fetchedBookings = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setBookings(fetchedBookings);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  const handleDetailsClick = (booking) => {
    setSelectedBooking(booking);
    setIsDetailsModalOpen(true);
  };

  const generatePDF = async (booking) => {
    setLoading(true);
    const ticketElement = document.getElementById("ticket-preview");

    try {
      const canvas = await html2canvas(ticketElement, {
        scale: 3.0, // Increased scale for higher quality and larger size
        useCORS: true,
        logging: false,
        backgroundColor: "#ffffff",
      });
      const imgData = canvas.toDataURL("image/jpeg", 1.0); // Maximum quality

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = 180; // Increased image width for larger ticket
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      // Center the image
      const x = (pageWidth - imgWidth) / 2;
      const y = (pageHeight - imgHeight) / 2 - 15; // Slight upward adjustment

      pdf.addImage(imgData, "JPEG", x, y, imgWidth, imgHeight);

      return pdf;
    } catch (error) {
      console.error("Error generating PDF:", error);
      throw new Error("Failed to generate PDF");
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadTicket = async () => {
    try {
      setLoading(true);
      setError(null);
      const pdf = await generatePDF(selectedBooking);
      pdf.save(`ticket-${selectedBooking.id}.pdf`);
    } catch (error) {
      console.error("Error downloading ticket:", error);
      setError("Failed to download ticket");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex">
      {/* Sidebar */}
      <div className="w-64 bg-white shadow-md p-6">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold">Dashboard</h2>
        </div>

        {/* User Information */}
        {user && (
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-2">Account</h3>
            <p className="text-gray-600 truncate">{user.email}</p>
            <p className="text-gray-600">{user.displayName || "User"}</p>
          </div>
        )}

        {/* Sidebar Navigation */}
        <nav className="space-y-2">
          {/* Logout Button */}
          <button
            onClick={handleLogout}
            className="w-full bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition flex items-center justify-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-2"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
            Logout
          </button>
        </nav>
      </div>

      {/* Main Content */}
      <main className="flex-1 p-10">
        <header className="bg-gray-800 text-white py-4 px-4 text-center mb-8">
          <h1 className="text-2xl font-bold">Dashboard</h1>
          <p className="text-sm">Home / Dashboard</p>
        </header>

        {/* User Information Card */}
        <div className="bg-white shadow-md rounded-lg p-4 mb-6">
          <h2 className="text-xl font-semibold mb-4">Account Information</h2>
          {user && (
            <div className="space-y-2">
              <p>
                <strong>Email:</strong> {user.email}
              </p>
              <p>
                <strong>Username:</strong> {user.displayName || "N/A"}
              </p>
            </div>
          )}
        </div>

        {/* Bookings Table */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-semibold mb-4">Recent Bookings</h2>
          <div className="overflow-x-auto">
            <table className="w-full min-w-[600px]">
              <thead>
                <tr className="bg-gray-200">
                  <th className="p-2 text-left">Event Title</th>
                  <th className="p-2 text-left">Event Date</th>
                  <th className="p-2 text-left">Booking Date</th>
                  <th className="p-2 text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                {bookings.map((booking, index) => (
                  <tr
                    key={booking.id}
                    className={index % 2 === 0 ? "bg-gray-50" : ""}
                  >
                    <td className="p-2">{booking.eventTitle}</td>
                    <td className="p-2">{booking.eventDate}</td>
                    <td className="p-2">
                      {new Date(booking.createdAt).toLocaleString()}
                    </td>
                    <td className="p-2">
                      <button
                        onClick={() => handleDetailsClick(booking)}
                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition"
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>

      {/* Details Modal */}
      {isDetailsModalOpen && selectedBooking && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full mx-4">
            <h2 className="text-xl font-bold mb-4">Booking Details</h2>

            {/* Ticket Preview Section */}
            <div
              id="ticket-preview"
              className="bg-white p-4 rounded-lg border border-gray-200 mb-4"
            >
              <div className="space-y-3">
                <p>
                  <strong>Event:</strong> {selectedBooking.eventTitle}
                </p>
                <p>
                  <strong>Date:</strong> {selectedBooking.eventDate}
                </p>
                <p>
                  <strong>Location:</strong> {selectedBooking.eventLocation}
                </p>
                <p>
                  <strong>Booking Date:</strong>{" "}
                  {new Date(selectedBooking.createdAt).toLocaleString()}
                </p>
              </div>
            </div>

            {error && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded mb-4">
                {error}
              </div>
            )}

            <div className="flex space-x-4 mt-4">
              <button
                onClick={handleDownloadTicket}
                disabled={loading}
                className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 transition disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? "Generating..." : "Download Ticket"}
              </button>
              <button
                onClick={() => setIsDetailsModalOpen(false)}
                className="bg-gray-200 text-gray-800 px-4 py-2 rounded hover:bg-gray-300 transition"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

import React, { useState, useRef, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  Menu,
  X,
  CalendarDays,
  Users,
  DollarSign,
  TicketCheck,
  Settings,
  Building2,
  Globe,
  Image as ImageIcon,
  Camera,
  Sun,
  Moon,
} from "lucide-react";
// import { QrReader } from "react-qr-reader";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import { db } from "../../firebase";
import { signOut } from "firebase/auth";
import firebase from "firebase/compat/app";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  updateDoc,
  query,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { addDoc, serverTimestamp } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";

// const auth = getAuth(app);

// const app = initializeApp(firebaseConfig);

const firebaseConfig = {
  apiKey: "AIzaSyBIu4pZw_GFUOZYknkd_jl1r3aSAV9n41g",
  authDomain: "connecticx-eca48.firebaseapp.com",
  projectId: "connecticx-eca48",
  storageBucket: "connecticx-eca48.appspot.com",
  messagingSenderId: "56674718618",
  appId: "1:56674718618:web:23565da9ecccacfb556023",
  measurementId: "G-LZQTWBPMSN",
};

firebase.initializeApp(firebaseConfig);
// const db = getFirestore(firebase.app());

// Theme context
const ThemeContext = React.createContext();

const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

// Dark mode toggle component
const DarkModeToggle = () => {
  const { darkMode, setDarkMode } = useTheme();

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <button
      onClick={toggleDarkMode}
      className="flex items-center gap-2 px-4 py-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
    >
      {darkMode ? (
        <Sun className="h-5 w-5 text-yellow-500" />
      ) : (
        <Moon className="h-5 w-5 text-gray-500" />
      )}
      <span className="text-sm font-medium">
        {darkMode ? "Light Mode" : "Dark Mode"}
      </span>
    </button>
  );
};

// Custom Card Components with theme support
const Card = ({ children, className = "", onClick }) => {
  const { darkMode } = useTheme();
  return (
    <div
      className={`${
        darkMode ? "bg-gray-800 text-white" : "bg-white"
      } rounded-lg shadow ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

const CardContent = ({ children, className = "" }) => (
  <div className={`p-4 ${className}`}>{children}</div>
);

// QR Scanner Component
const QRScanner = () => {
  const [scannedCode, setScannedCode] = useState(null);
  const [attendeeName, setAttendeeName] = useState(null); // State for storing attendee's name
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  const handleScan = async (result) => {
    if (result) {
      try {
        const scannedData = JSON.parse(result.text);
        const { ticketId, attendeeName } = scannedData;
        setScannedCode(ticketId);
        setAttendeeName(attendeeName); // Set attendee name from QR data

        // Check if the ticketId exists in Firestore
        const docRef = doc(db, "orders", ticketId);
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) {
          setIsPopupOpen(true); // Show the allow/deny popup if ticket exists
        } else {
          alert("Ticket not found in the database.");
        }
      } catch (error) {
        console.error("Error parsing QR code:", error);
        alert("Invalid QR code format.");
      }
    }
  };

  const handlePopupClose = async (shouldAllow) => {
    if (shouldAllow) {
      // Add to 'eventEntries' collection on Allow
      const eventEntryRef = doc(collection(db, "eventEntries"), scannedCode);
      await setDoc(eventEntryRef, {
        ticketId: scannedCode,
        timestamp: new Date(),
        allowed: true,
      });

      setIsAllowed(true);
      alert("User has been authenticated to enter the event.");
    } else {
      alert("Entry denied.");
    }
    setIsPopupOpen(false);
    setScannedCode(null);
    setAttendeeName(null); // Reset attendee name after closing popup
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-6">Ticket Scanner</h2>
      <div className="max-w-md mx-auto">
        <QrReader
          onResult={handleScan}
          onError={(error) => console.error(error)}
          constraints={{ facingMode: "environment" }}
          className="w-full"
        />
        {scannedCode && (
          <div className="mt-4 p-4 bg-purple-100 rounded">
            <p className="text-purple-800">
              Last scanned ticket ID: {scannedCode}
            </p>
            {attendeeName && (
              <p className="text-gray-700">Attendee: {attendeeName}</p>
            )}
            {isAllowed && (
              <p className="text-green-600 font-bold">
                ✓ User has been authenticated to enter the event
              </p>
            )}
          </div>
        )}
      </div>
      {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-xl font-bold mb-4">Allow entry?</h3>
            <p>The scanned QR code is present in the database. Allow entry?</p>
            <div className="flex justify-end mt-4">
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
                onClick={() => handlePopupClose(false)}
              >
                Deny
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                onClick={() => handlePopupClose(true)}
              >
                Allow
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Image Upload Component
const ImageUpload = ({ onImageUpload }) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleFile = (file) => {
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        onImageUpload(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload an image file");
    }
  };

  return (
    <div
      className={`border-2 border-dashed rounded-lg p-6 ${
        dragActive ? "border-purple-500 bg-purple-50" : "border-gray-300"
      }`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      onClick={() => inputRef.current.click()}
    >
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        accept="image/*"
        onChange={handleChange}
      />
      <div className="flex items-center justify-center flex-col">
        <ImageIcon className="h-12 w-12 text-gray-400 mb-2" />
        <p className="text-sm text-gray-500">
          {dragActive ? "Drop the image here" : "Drop files here to upload"}
        </p>
        <p className="text-xs text-gray-400">Image Size 1170x570</p>
      </div>
    </div>
  );
};

// Main Layout Component
const OrganizerDashboard = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [darkMode, setDarkMode] = useState(false);
  const [history, setHistory] = useState(["dashboard"]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      // Optionally, you can redirect the user or update app state
      // For example, you might want to use React Router or a state management solution
    } catch (error) {
      console.error("Logout error:", error);
      alert("Failed to log out. Please try again.");
    }
  };

  const handleTabChange = (tabId) => {
    setHistory([...history, tabId]);
    setActiveTab(tabId);
    setIsSidebarOpen(false);
  };

  const handleBack = () => {
    if (history.length > 1) {
      const newHistory = history.slice(0, -1);
      setHistory(newHistory);
      setActiveTab(newHistory[newHistory.length - 1]);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "event-management":
        return <EventManagement onBack={handleBack} />;
      case "event-bookings":
        return <EventBookings />;
      case "transactions":
        return <Transactions />;
      case "support-tickets":
        return <SupportTickets />;
      case "scanner":
        return <QRScanner />;
      default:
        return <DashboardContent />;
    }
  };

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <div
        className={`flex h-screen ${
          darkMode ? "bg-gray-900 text-white" : "bg-white text-gray-800"
        }`}
      >
        {/* Sidebar */}

        <div
          className={`z-10 fixed inset-0 bg-gray-800 text-white p-4 transition-transform duration-300 ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } md:relative md:translate-x-0 md:w-64 md:shadow-md`}
        >
          <nav className="mt-16 md:mt-6">
            <div className="flex flex-col items-center mb-6 md:hidden">
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="p-2 rounded-full hover:bg-gray-700 mb-4"
              >
                {isSidebarOpen ? (
                  <X className="h-5 w-5" />
                ) : (
                  <Menu className="h-5 w-5" />
                )}
              </button>
              <DarkModeToggle />
            </div>
            <div className="hidden md:flex justify-between items-center mb-6">
              <DarkModeToggle />
              <button
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="p-2 rounded-full hover:bg-gray-700 md:hidden"
              >
                {isSidebarOpen ? (
                  <X className="h-5 w-5" />
                ) : (
                  <Menu className="h-5 w-5" />
                )}
              </button>
            </div>
            <ul className="space-y-2">
              {[
                {
                  id: "dashboard",
                  label: "Dashboard",
                  icon: <Settings className="h-4 w-4" />,
                },
                {
                  id: "event-management",
                  label: "Event Management",
                  icon: <CalendarDays className="h-4 w-4" />,
                },
                {
                  id: "event-bookings",
                  label: "Event Bookings",
                  icon: <TicketCheck className="h-4 w-4" />,
                },
                {
                  id: "transactions",
                  label: "Transactions",
                  icon: <DollarSign className="h-4 w-4" />,
                },
                {
                  id: "support-tickets",
                  label: "Support Tickets",
                  icon: <Users className="h-4 w-4" />,
                },
                {
                  id: "scanner",
                  label: "Ticket Scanner",
                  icon: <Camera className="h-4 w-4" />,
                },
                {
                  id: "logout",
                  label: "Log Out",
                  icon: (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="h-4 w-4"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1="21" x2="9" y1="12" y2="12" />
                    </svg>
                  ),
                },
              ].map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    if (item.id === "logout") {
                      handleLogout();
                    } else {
                      handleTabChange(item.id);
                    }
                  }}
                  className={`flex items-center gap-2 p-2 rounded cursor-pointer ${
                    activeTab === item.id
                      ? "bg-purple-100 text-purple-600"
                      : "hover:bg-gray-700"
                  }`}
                >
                  {item.icon}
                  {item.label}
                </li>
              ))}
            </ul>
          </nav>
        </div>

        {/* Main content */}
        <div className="flex-1 p-8 overflow-y-auto">
          <div className="flex justify-between items-center mb-4 md:hidden">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              {isSidebarOpen ? (
                <X className="h-5 w-5" />
              ) : (
                <Menu className="h-5 w-5" />
              )}
            </button>
            {/* <h1 className="text-2xl font-bold">Dashboard</h1> */}
          </div>
          {history.length > 1 && (
            <button
              onClick={handleBack}
              className="mb-4 text-purple-600 hover:text-purple-700 flex items-center gap-2 dark:text-purple-400 dark:hover:text-purple-300"
            >
              ← Back
            </button>
          )}
          {/* <div className="mt-6 flex justify-center">
            <DarkModeToggle />
          </div> */}
          {renderContent()}
        </div>
      </div>
    </ThemeContext.Provider>
  );
};

// Modified Event Management Component with image upload
const EventManagement = ({ onBack }) => {
  const [eventType, setEventType] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const { darkMode } = useTheme();

  const handleCardClick = (type) => {
    setEventType(type);
    setShowForm(true);
  };

  const handleImageUpload = (imageData) => {
    setUploadedImage(imageData);
  };

  return (
    <div>
      <h2 className="text-2xl mb-6 flex items-center gap-2">
        <CalendarDays className="h-6 w-6" />
        Event Management
      </h2>

      {!showForm && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
          <Card
            className="cursor-pointer hover:shadow-lg transition-shadow"
            onClick={() => handleCardClick("online")}
          >
            <CardContent className="flex items-center gap-4 p-6">
              <Globe className="h-8 w-8 text-purple-600" />
              <div>
                <h3 className="text-lg font-medium">Add Online Event</h3>
                <p
                  className={`${darkMode ? "text-gray-400" : "text-gray-500"}`}
                >
                  Create a virtual event with online access
                </p>
              </div>
            </CardContent>
          </Card>

          <Card
            className="cursor-pointer hover:shadow-lg transition-shadow"
            onClick={() => handleCardClick("venue")}
          >
            <CardContent className="flex items-center gap-4 p-6">
              <Building2 className="h-8 w-8 text-purple-600" />
              <div>
                <h3 className="text-lg font-medium">Add Venue Event</h3>
                <p
                  className={`${darkMode ? "text-gray-400" : "text-gray-500"}`}
                >
                  Create an in-person event at a physical location
                </p>
              </div>
            </CardContent>
          </Card>
        </div>
      )}

      {showForm && (
        <div>
          {eventType === "online" ? (
            <OnlineEventForm
              uploadedImage={uploadedImage}
              onImageUpload={handleImageUpload}
            />
          ) : (
            <VenueEventForm
              uploadedImage={uploadedImage}
              onImageUpload={handleImageUpload}
            />
          )}
        </div>
      )}
    </div>
  );
};

const OnlineEventForm = ({ uploadedImage, onImageUpload }) => {
  const [formData, setFormData] = useState({
    dateType: "",
    startDate: "",
    startTime: "",
    endDate: "",
    eventTitle: "",
    category: "",
    description: "",
    totalTickets: "",
    price: "",
    isFree: false,
    imageUrl: "",
  });
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      alert("Please log in to create an event");
      return;
    }

    try {
      const eventData = {
        dateType: formData.dateType,
        startDate: formData.startDate,
        startTime: formData.startTime,
        endDate: formData.endDate,
        eventTitle: formData.eventTitle,
        category: formData.category,
        description: formData.description,
        totalTickets: Number(formData.totalTickets),
        price: formData.isFree ? 0 : Number(formData.price),
        isFree: formData.isFree,
        imageUrl: uploadedImage || "",
        eventType: "online",
        createdAt: serverTimestamp(),
        status: "active",
        // Add user information
        organizerId: user.uid,
        organizerEmail: user.email,
        lastUpdatedAt: serverTimestamp(),
      };

      // Validation
      const requiredFields = [
        "dateType",
        "startDate",
        "startTime",
        "endDate",
        "eventTitle",
        "category",
        "description",
        "totalTickets",
      ];

      const missingFields = requiredFields.filter((field) => !eventData[field]);
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
      }

      const eventsRef = collection(db, "events");
      const docRef = await addDoc(eventsRef, eventData);

      console.log("Event created with ID: ", docRef.id);

      setFormData({
        dateType: "",
        startDate: "",
        startTime: "",
        endDate: "",
        eventTitle: "",
        category: "",
        description: "",
        totalTickets: "",
        price: "",
        isFree: false,
        imageUrl: "",
      });

      if (onImageUpload) onImageUpload(null);

      alert("Event created successfully!");
    } catch (error) {
      console.error("Error creating event: ", error);
      alert(`Error creating event: ${error.message}`);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`space-y-6 ${
        DarkModeToggle ? "bg-gray-800" : "bg-white"
      } p-6 rounded-lg shadow`}
    >
      <h3 className="text-xl font-medium mb-6">Add Online Event</h3>

      <div className="space-y-4">
        {/* Image Upload Section */}
        {uploadedImage ? (
          <div className="relative">
            <img
              src={uploadedImage}
              alt="Uploaded event"
              className="w-full h-64 object-cover rounded-lg"
            />
            <button
              type="button"
              onClick={() => onImageUpload(null)}
              className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
            >
              ×
            </button>
          </div>
        ) : (
          <ImageUpload onImageUpload={onImageUpload} />
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">Date Type*</label>
            <select
              name="dateType"
              value={formData.dateType}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            >
              <option value="">Select Date Type</option>
              <option value="single">Single Day</option>
              <option value="multiple">Multiple Days</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              Start Date*
            </label>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              Start Time*
            </label>
            <input
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">End Date*</label>
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">
              Event Title*
            </label>
            <input
              type="text"
              name="eventTitle"
              value={formData.eventTitle}
              onChange={handleInputChange}
              placeholder="Enter Event Name"
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Category*</label>
            <select
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            >
              <option value="">Select Category</option>
              <option value="conference">Conference</option>
              <option value="workshop">Workshop</option>
              <option value="webinar">Webinar</option>
            </select>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Description*</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            rows="4"
            className="w-full p-2 border rounded"
            required
          ></textarea>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">
              Total Available Tickets*
            </label>
            <input
              type="number"
              name="totalTickets"
              value={formData.totalTickets}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">
              Price (USD)*
            </label>
            <div className="flex items-center gap-2">
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Enter Ticket Price"
                className="w-full p-2 border rounded"
                disabled={formData.isFree}
                required={!formData.isFree}
              />
              <label className="flex items-center gap-1">
                <input
                  type="checkbox"
                  name="isFree"
                  checked={formData.isFree}
                  onChange={handleInputChange}
                />
                Free
              </label>
            </div>
          </div>
        </div>
      </div>

      <button
        type="submit"
        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
      >
        Save Event
      </button>
    </form>
  );
};

const VenueEventForm = ({ uploadedImage, onImageUpload }) => {
  const [formData, setFormData] = useState({
    eventTitle: "",
    category: "",
    address: "",
    country: "",
    state: "",
    city: "",
    latitude: "",
    longitude: "",
    description: "",
    refundPolicy: "",
    imageUrl: "",
  });
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      alert("Please log in to create an event");
      return;
    }

    try {
      const eventData = {
        eventTitle: formData.eventTitle,
        category: formData.category,
        address: formData.address,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        latitude: formData.latitude ? Number(formData.latitude) : null,
        longitude: formData.longitude ? Number(formData.longitude) : null,
        description: formData.description,
        refundPolicy: formData.refundPolicy,
        imageUrl: uploadedImage || "",
        eventType: "venue",
        createdAt: serverTimestamp(),
        status: "active",
        // Add user information
        organizerId: user.uid,
        organizerEmail: user.email,
        lastUpdatedAt: serverTimestamp(),
      };

      const requiredFields = [
        "eventTitle",
        "category",
        "address",
        "country",
        "city",
        "description",
      ];

      const missingFields = requiredFields.filter((field) => !eventData[field]);
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
      }

      const eventsRef = collection(db, "events");
      const docRef = await addDoc(eventsRef, eventData);

      console.log("Venue event created with ID: ", docRef.id);

      setFormData({
        eventTitle: "",
        category: "",
        address: "",
        country: "",
        state: "",
        city: "",
        latitude: "",
        longitude: "",
        description: "",
        refundPolicy: "",
        imageUrl: "",
      });

      if (onImageUpload) onImageUpload(null);

      alert("Venue event created successfully!");
    } catch (error) {
      console.error("Error creating venue event: ", error);
      alert(`Error creating venue event: ${error.message}`);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={`space-y-6 ${
        DarkModeToggle ? "bg-gray-800" : "bg-white"
      } p-6 rounded-lg shadow`}
    >
      <h3 className="text-xl font-medium mb-6">Add Venue Event</h3>

      <div className="space-y-4">
        {/* Image Upload Section */}
        {uploadedImage ? (
          <div className="relative">
            <img
              src={uploadedImage}
              alt="Uploaded event"
              className="w-full h-64 object-cover rounded-lg"
            />
            <button
              type="button"
              onClick={() => onImageUpload(null)}
              className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
            >
              ×
            </button>
          </div>
        ) : (
          <ImageUpload onImageUpload={onImageUpload} />
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">
              Event Title*
            </label>
            <input
              type="text"
              name="eventTitle"
              value={formData.eventTitle}
              onChange={handleInputChange}
              placeholder="Enter Event Name"
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Category*</label>
            <select
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="w-full p-2 border rounded"
              required
            >
              <option value="">Select Category</option>
              <option value="conference">Conference</option>
              <option value="workshop">Workshop</option>
              <option value="concert">Concert</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Address*</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Enter Address"
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Country*</label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              placeholder="Enter Country"
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">State</label>
            <input
              type="text"
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              placeholder="Enter State"
              className="w-full p-2 border rounded"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">City*</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              placeholder="Enter City"
              className="w-full p-2 border rounded"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Latitude</label>
            <input
              type="text"
              name="latitude"
              value={formData.latitude}
              onChange={handleInputChange}
              placeholder="Enter Latitude"
              className="w-full p-2 border rounded"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Longitude</label>
            <input
              type="text"
              name="longitude"
              value={formData.longitude}
              onChange={handleInputChange}
              placeholder="Enter Longitude"
              className="w-full p-2 border rounded"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">Description*</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            rows="4"
            className="w-full p-2 border rounded"
            required
          ></textarea>
        </div>

        <div>
          <label className="block text-sm font-medium mb-1">
            Refund Policy
          </label>
          <textarea
            name="refundPolicy"
            value={formData.refundPolicy}
            onChange={handleInputChange}
            rows="4"
            className="w-full p-2 border rounded"
          ></textarea>
        </div>
      </div>

      <button
        type="submit"
        className="bg-green-600 text-white px-4 py-2 rounded hover:bg-green-700"
      >
        Save Event
      </button>
    </form>
  );
};

// Event Bookings Component
const EventBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        // Ensure user is authenticated
        if (!auth.currentUser) {
          setError("Please log in to view bookings");
          setLoading(false);
          return;
        }

        // Create a query to fetch verified payments (completed bookings)
        const verifiedPaymentsQuery = query(collection(db, "verifiedPayments"));

        // Execute the query
        const querySnapshot = await getDocs(verifiedPaymentsQuery);

        // Transform the documents into bookings
        const fetchedBookings = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            event: data.eventTitle,
            customer: `${data.customerName}`,
            paid: `₹${data.paymentDetails?.amount / 100 || 1}`, // Assuming amount is in paisa
            received: `₹${data.paymentDetails?.amount / 100 || 1}`,
            via: "Razorpay", // Default payment method
            status: data.paymentStatus === "success" ? "Completed" : "Pending",
            scanStatus: "Not Scanned", // You'll need to implement scanning logic
            orderId: data.orderId,
            email: data.customerEmail,
          };
        });

        setBookings(fetchedBookings);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching bookings:", err);
        setError("Failed to fetch bookings");
        setLoading(false);
      }
    };

    fetchBookings();
  }, [auth]);

  const handleViewTicket = async (booking) => {
    try {
      // Fetch the order details to get event information
      const orderDoc = await getDoc(doc(db, "orders", booking.orderId));
      const orderData = orderDoc.data();

      // Open a new window to display or download the ticket
      // You'll need to implement the ticket generation logic similar to your confirmation page
      window.open(`/confirmation/${booking.orderId}`, "_blank");
    } catch (err) {
      console.error("Error viewing ticket:", err);
      alert("Failed to retrieve ticket");
    }
  };

  if (loading) {
    return (
      <div className="text-center py-6">
        <p>Loading bookings...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
        {error}
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-2xl mb-6 flex items-center gap-2">Event Bookings</h2>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Booking ID
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Event
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Customer
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Cust. Paid
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Org. Received
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Paid Via
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Scan Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {bookings.map((booking) => (
              <tr key={booking.id}>
                <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
                  {booking.id}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {booking.event}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {booking.customer}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {booking.paid}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {booking.received}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {booking.via}
                </td>
                <td className="px-4 py-3 text-sm">
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      booking.status === "Completed"
                        ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100"
                        : "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100"
                    }`}
                  >
                    {booking.status}
                  </span>
                </td>
                <td className="px-4 py-3 text-sm">
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      booking.scanStatus === "Scanned"
                        ? "bg-blue-100 text-blue-800 dark:bg-blue-800 dark:text-blue-100"
                        : "bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-200"
                    }`}
                  >
                    {booking.scanStatus}
                  </span>
                </td>
                <td className="px-4 py-3 text-sm">
                  <button
                    onClick={() => handleViewTicket(booking)}
                    className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-500"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {bookings.length === 0 && (
          <div className="text-center py-6 text-gray-500">
            No bookings found
          </div>
        )}
      </div>
    </div>
  );
};

// Transactions Component
const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        // Ensure user is authenticated
        if (!auth.currentUser) {
          setError("Please log in to view transactions");
          setLoading(false);
          return;
        }

        // Create a query to fetch verified payments
        const verifiedPaymentsQuery = query(collection(db, "verifiedPayments"));

        // Execute the query
        const querySnapshot = await getDocs(verifiedPaymentsQuery);

        // Fetch additional details for each transaction
        const fetchedTransactions = await Promise.all(
          querySnapshot.docs.map(async (paymentDoc) => {
            const paymentData = paymentDoc.data();

            try {
              // Fetch order details to get event title and customer name
              const orderDoc = await getDoc(
                doc(db, "orders", paymentData.orderId)
              );
              const orderData = orderDoc.data();

              return {
                id: paymentDoc.id,
                type: "Event Booking",
                method: "Razorpay",
                eventTitle: orderData?.eventTitle || "Unknown Event",
                customerName: orderData?.customerName || "Unknown Customer",
                amount: `₹${paymentData.paymentDetails?.amount / 100 || 0}`,
                status:
                  paymentData.paymentStatus === "success"
                    ? "Completed"
                    : "Pending",
                email: orderData?.customerEmail || "N/A",
              };
            } catch (orderFetchError) {
              console.error(
                `Error fetching order for payment ${paymentDoc.id}:`,
                orderFetchError
              );
              return {
                id: paymentDoc.id,
                type: "Event Booking",
                method: "Razorpay",
                eventTitle: "Error Retrieving Event",
                customerName: "Error Retrieving Customer",
                amount: `₹${paymentData.paymentDetails?.amount / 100 || 0}`,
                status:
                  paymentData.paymentStatus === "success"
                    ? "Completed"
                    : "Pending",
              };
            }
          })
        );

        setTransactions(fetchedTransactions);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching transactions:", err);
        setError("Failed to fetch transactions");
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [auth]);

  if (loading) {
    return (
      <div className="text-center py-6">
        <p>Loading transactions...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
        {error}
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-2xl mb-6 flex items-center gap-2">
        <DollarSign className="h-6 w-6" />
        Transactions
      </h2>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto">
        <table className="min-w-full">
          <thead className="bg-gray-50 dark:bg-gray-700">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Transaction ID
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Event
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Customer
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Customer Email
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Amount
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Payment Method
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                  {transaction.id}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {transaction.eventTitle}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {transaction.customerName}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {transaction.email}
                </td>
                <td className="px-4 py-3 text-sm text-green-600 dark:text-green-400">
                  {transaction.amount}
                </td>
                <td className="px-4 py-3 text-sm text-gray-900 dark:text-gray-200">
                  {transaction.method}
                </td>
                <td className="px-4 py-3 text-sm">
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      transaction.status === "Completed"
                        ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100"
                        : "bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100"
                    }`}
                  >
                    {transaction.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {transactions.length === 0 && (
          <div className="text-center py-6 text-gray-500">
            No transactions found
          </div>
        )}
      </div>
    </div>
  );
};

// Support Tickets Component
const SupportTickets = () => {
  const { darkMode } = useTheme();

  const tickets = [
    {
      id: "ST001",
      email: "john@example.com",
      subject: "Cannot access event link",
      status: "Open",
    },
    {
      id: "ST002",
      email: "jane@example.com",
      subject: "Refund request",
      status: "Closed",
    },
  ];

  return (
    <div>
      <h2 className="text-2xl mb-6 flex items-center gap-2">
        <Users className="h-6 w-6" />
        Support Tickets
      </h2>

      <div
        className={`bg-white dark:bg-gray-800 rounded-lg shadow overflow-x-auto`}
      >
        <table
          className={`min-w-full ${
            darkMode ? "text-gray-200" : "text-gray-800"
          }`}
        >
          <thead className={`bg-gray-50 dark:bg-gray-700`}>
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Ticket ID
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Email
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Subject
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
            {tickets.map((ticket) => (
              <tr key={ticket.id}>
                <td
                  className={`px-4 py-3 whitespace-nowrap ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {ticket.id}
                </td>
                <td
                  className={`px-4 py-3 ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {ticket.email}
                </td>
                <td
                  className={`px-4 py-3 ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  {ticket.subject}
                </td>
                <td className="px-4 py-3">
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      ticket.status === "Open"
                        ? "bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100"
                        : "bg-gray-100 text-gray-800 dark:bg-gray-600 dark:text-gray-200"
                    }`}
                  >
                    {ticket.status}
                  </span>
                </td>
                <td
                  className={`px-4 py-3 ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                >
                  <button className="text-blue-600 hover:text-blue-800 mr-2 dark:text-blue-400 dark:hover:text-blue-500">
                    View
                  </button>
                  <button className="text-green-600 hover:text-green-800 dark:text-green-400 dark:hover:text-green-500">
                    Reply
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Original DashboardContent Component (stats and charts)
const DashboardContent = () => {
  return (
    <div>
      <h2 className="text-2xl mb-4 text-gray-800">Welcome back, organizer!</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <Card>
          <CardContent className="p-4">
            <h3 className="text-sm font-medium text-gray-500">My Balance</h3>
            <p className="text-2xl font-bold text-gray-800">$453.25</p>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4">
            <h3 className="text-sm font-medium text-gray-500">Events</h3>
            <p className="text-2xl font-bold text-gray-800">5</p>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4">
            <h3 className="text-sm font-medium text-gray-500">
              Total Event Bookings
            </h3>
            <p className="text-2xl font-bold text-gray-800">5</p>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4">
            <h3 className="text-sm font-medium text-gray-500">
              Total Transaction
            </h3>
            <p className="text-2xl font-bold text-gray-800">6</p>
          </CardContent>
        </Card>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Card>
          <CardContent className="p-4">
            <h3 className="text-lg font-medium mb-4">
              Event Booking Monthly Income (2024)
            </h3>
            <div className="h-[300px] flex items-center justify-center bg-gray-100 text-gray-400">
              Chart placeholder
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardContent className="p-4">
            <h3 className="text-lg font-medium mb-4">
              Monthly Event Bookings (2024)
            </h3>
            <div className="h-[300px] flex items-center justify-center bg-gray-100 text-gray-400">
              Chart placeholder
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default OrganizerDashboard;

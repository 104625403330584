import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { getAuth } from "firebase/auth";
const auth = getAuth();

// Event details from the previous component
const eventDetails = {
  1: {
    title: "Yoga and Mindfulness by Utsaahi",
    date: "December 15, 2024",
    time: "7:00 AM - 8:00 AM",
    location: "Japanese Garden, Nagpur",
  },
  2: {
    title: "Digital Marketing Bootcamp",
    date: "September 5, 2024",
    time: "9:00 AM - 4:00 PM",
    location: "TechHub, Austin, TX",
  },
  3: {
    title: "Entrepreneurs' Roundtable",
    date: "August 20, 2024",
    time: "6:00 PM - 8:30 PM",
    location: "The Loft, San Francisco, CA",
  },
};

const CheckoutPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventId = location.state?.eventId || "1";
  const event = eventDetails[eventId];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [billingDetails, setBillingDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
    address: "",
  });

  const validateForm = () => {
    if (!billingDetails.phone.match(/^\+?[\d\s-]{10,}$/)) {
      setError("Please enter a valid phone number");
      return false;
    }
    if (
      billingDetails.zipCode &&
      !billingDetails.zipCode.match(/^[\d\s-]{4,10}$/)
    ) {
      setError("Please enter a valid postal code");
      return false;
    }
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails((prev) => ({ ...prev, [name]: value }));
    setError(null); // Clear error when user makes changes
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Add order to Firestore
      const docRef = await addDoc(collection(db, "orders"), {
        ...billingDetails,
        eventId,
        eventTitle: event.title,
        eventDate: event.date,
        eventLocation: event.location,
        ticketPrice: 0, // Set to free
        paymentStatus: "confirmed",
        createdAt: new Date().toISOString(),
      });

      // Navigate to confirmation page
      navigate(`/confirmation/${docRef.id}`);
    } catch (error) {
      console.error("Error during checkout:", error);
      setError(
        error.message || "An unexpected error occurred. Please try again."
      );
      setLoading(false);
    }
  };

  return (
    <div className="bg-white">
      <header className="bg-gray-800 text-white p-4">
        <h1 className="text-2xl font-bold">Checkout</h1>
        <p>Home / Checkout</p>
      </header>
      <main className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2">
            <h2 className="text-2xl font-bold mb-4">Ticket Registration</h2>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block mb-2">First Name *</label>
                  <input
                    type="text"
                    name="firstName"
                    value={billingDetails.firstName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2">Last Name *</label>
                  <input
                    type="text"
                    name="lastName"
                    value={billingDetails.lastName}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block mb-2">Email *</label>
                  <input
                    type="email"
                    name="email"
                    value={billingDetails.email}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2">Phone *</label>
                  <input
                    type="tel"
                    name="phone"
                    value={billingDetails.phone}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block mb-2">Country *</label>
                  <input
                    type="text"
                    name="country"
                    value={billingDetails.country}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2">State</label>
                  <input
                    type="text"
                    name="state"
                    value={billingDetails.state}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div>
                  <label className="block mb-2">City *</label>
                  <input
                    type="text"
                    name="city"
                    value={billingDetails.city}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-2">Zip/Post Code</label>
                  <input
                    type="text"
                    name="zipCode"
                    value={billingDetails.zipCode}
                    onChange={handleInputChange}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
              <div className="mt-4">
                <label className="block mb-2">Address *</label>
                <textarea
                  name="address"
                  value={billingDetails.address}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded"
                  rows="3"
                  required
                ></textarea>
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  disabled={loading}
                  className="bg-teal-500 text-white py-2 rounded-lg w-full disabled:opacity-50"
                >
                  {loading ? "Processing..." : "Proceed to Ticket"}
                </button>
              </div>
            </form>
          </div>

          {/* Order Summary */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h2 className="text-xl font-bold mb-4">Ticket Details</h2>
            <div className="bg-white p-4 rounded-lg mb-4">
              <h3 className="font-bold">{event.title}</h3>
              <p className="text-sm text-gray-600">
                {event.date} @ {event.time}
              </p>
              <p className="text-sm text-gray-600">{event.location}</p>
            </div>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span>Standard Ticket</span>
                <span>1x</span>
              </div>
              <div className="flex justify-between">
                <span>Total Tickets</span>
                <span>1</span>
              </div>
              <div className="flex justify-between">
                <span>Ticket Price</span>
                <span>Free</span>
              </div>
              <div className="flex justify-between font-bold">
                <span>Total</span>
                <span>₹0</span>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CheckoutPage;

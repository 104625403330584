import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../images/arrows.png";
import logo from "../../images/logo.png";

function Footer() {
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  return (
    <footer className="bg-gray-800 text-white py-8 relative">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Logo and description */}
          <div>
            <img src={logo} alt="Connecticx Logo" className="w-16 mb-4" />
            <p className="text-gray-400">
              Welcome To Connecticx – Your Ultimate Platform For Discovering And
              Creating Exciting Events In Your City. Whether you're looking to
              attend a thrilling adventure, a networking seminar, or a
              fun-filled activity, we've got you covered. With Connecticx, you
              can easily explore and purchase tickets for a wide range of events
              or create your own, tailored to fun, learning, sports, business,
              and more.
            </p>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <Link to="/contact" className="text-gray-400 hover:text-white">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-400 hover:text-white">
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/organizer-info"
                  className="text-gray-400 hover:text-white"
                >
                  Organizers
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-400 hover:text-white">
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
            <p className="text-gray-400">
              India, Maharashtra
              <br />
              Nagpur, Shivaji Nagar
              <br />
              440010
            </p>
            <p className="text-gray-400">Email: connecticx7@gmail.com</p>
            <p className="text-gray-400">Phone: +91 (12345) 67890</p>
          </div>
        </div>

        {/* Copyright and scroll-to-top */}
        <div className="mt-8 pt-8 border-t border-gray-700 text-center relative">
          <p className="text-gray-400">
            &copy; 2024 Connecticx. All rights reserved.
          </p>
          <button
            onClick={scrollToTop}
            className="absolute right-0 bottom-0 mr-6 mb-6 p-3 rounded-full bg-pink-500 hover:bg-pink-600 transition duration-300"
          >
            <img src={arrow} alt="Scroll to top" className="w-8 h-8" />
          </button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";
import img2 from "../../images/2.jpg";

function Hero() {
  return (
    <div
      className="bg-cover bg-center text-white flex items-center justify-center"
      style={{
        backgroundImage: `url(${img2})`,
        height: "110vh", // Full viewport height
        backgroundSize: "cover", // Ensures full coverage
        backgroundPosition: "top", // Aligns the image to the top
        backgroundRepeat: "no-repeat", // Prevents the image from repeating
        backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay for contrast
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="container mx-auto px-9 flex flex-col items-center text-center">
        <h1 className="text-5xl font-bold mb-5">
          Your Gateway to Memorable Moments.
        </h1>
        <p className="text-xl mb-8 max-w-2xl">
          Welcome to Connecticx! Discover and create exciting local events with
          ease. From adventures and seminars to fun activities, explore and buy
          tickets or craft your own events.
        </p>
        <button
          className="bg-purple-600 text-white font-bold py-3 px-8 rounded-full hover:bg-purple-700 transition duration-300"
          onClick={() => window.scrollBy({ top: 900, behavior: "smooth" })} // Adjust "top" value as needed
        >
          Let's Start
        </button>
      </div>
    </div>
  );
}

export default Hero;
